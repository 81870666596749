import createLucideIcon from '../createLucideIcon';

const BatteryWarning = createLucideIcon('BatteryWarning', [
  ['path', { d: 'M14 7h2a2 2 0 0 1 2 2v6c0 1 -1 2 -2 2h-2', key: '7x2rx5' }],
  ['path', { d: 'M6 7h-2a2 2 0 0 0-2 2v6c0 1 1 2 2 2h2', key: '1r83cn' }],
  ['line', { x1: '22', x2: '22', y1: '11', y2: '13', key: '4dh1rd' }],
  ['line', { x1: '10', x2: '10', y1: '7', y2: '13', key: '1uzyus' }],
  ['line', { x1: '10', x2: '10', y1: '17', y2: '17.01', key: '1y8k4g' }],
]);

export default BatteryWarning;
