import createLucideIcon from '../createLucideIcon';

const PictureInPicture2 = createLucideIcon('PictureInPicture2', [
  [
    'path',
    {
      d: 'M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4',
      key: 'daa4of',
    },
  ],
  [
    'rect',
    { x: '12', y: '13', width: '10', height: '7', rx: '2', key: '1wczht' },
  ],
]);

export default PictureInPicture2;
