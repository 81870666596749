import createLucideIcon from '../createLucideIcon';

const DatabaseBackup = createLucideIcon('DatabaseBackup', [
  ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3', key: 'msslwz' }],
  ['path', { d: 'M3 12c0 1.18 2.03 2.2 5 2.7', key: 'ecdgp9' }],
  ['path', { d: 'M21 5v4.5', key: '18xpbp' }],
  ['path', { d: 'm12 16 1.27-1.35a4.75 4.75 0 1 1 .41 5.74', key: '1wi0wz' }],
  ['path', { d: 'M12 12v4h4', key: '1bxaet' }],
  ['path', { d: 'M3 5v14c0 1.43 2.97 2.63 7 2.93', key: '5qsoub' }],
]);

export default DatabaseBackup;
